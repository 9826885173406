export default function About() {
  return (
    <div className="About">
      <h1>
        Sam Arroyo is a film photographer capturing honest moments of life,
        mamas, and the ocean. She lives on the Big Island in Hawaii.
      </h1>
    </div>
  );
}
